<template>
  <div>
    <Header />

    <ActivateSection />

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import ActivateSection from "@/components/sections/ActivateSection";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
export default {
  name: "ActivateAccount",
  components: {
    Header,
    ActivateSection,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
  },
  created() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      if (sessionStorage.getItem("token") != null) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
</style>
