<template>
  <div>
    <div class="login-register_area">
      <div class="container">
        <div class="row col-xs-12">
          <div
            class="col-md-8 col-lg-8 col-sm-12 col-xs-12 center-block"
            style="margin: auto"
          >
            <form action class="form" @submit.prevent="active">
              <div class="login-form">
                <h4 class="login-title">Activa tu cuenta</h4>
                <p>
                  Hemos enviado un codigo de verificación a tu correo electrónico, ingresa
                  el codigo para activar tu cuenta.
                </p>
                <div class="row center-block" style="margin: auto">
                  <div class="col-md-6 col-12 mb--20">
                    <label>Código *</label>
                    <input
                      v-model="code"
                      id="code"
                      required
                      oninput="if(this.value.length > 4){this.value = this.value.slice(0,4);}"
                      type="number"
                      min="1000"
                      max="9999"
                      style="
                        padding: 6px;
                        width: 20%;
                        margin-left: 40%;
                        text-align: center;
                      "
                      placeholder="0000"
                      maxlength="4"
                    />
                  </div>
                  <div class="col-md-12">
                    <button type="submit" class="btn ht-btn--round">Activar</button>
                    <p v-if="error" style="padding-top: 10px" class="error">
                      Código inválido, intente de nuevo por favor.
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import axios from "axios";
var urlSignupActivate = process.env.VUE_APP_AUTH_API + "api/auth/signup/activate/";
export default {
  name: "ActivateSection",
  data: () => ({
    code: "",
    error: false,
  }),
  methods: {
    active() {
      console.log(this.code);
      axios
        .get(urlSignupActivate + this.code)
        .then((response) => {
          this.$swal({
            title: `¡Hola!`,
            html: `Activaste tu cuenta satisfactoriamente.<br>Inicia sesión para disfrutar de nuestra Ecosistema Digital`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: "#e94e1b",
            confirmButtonText: "Aceptar",
          });
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;
  background-color: #003d74;
}
</style>
